import { Vue, Component, Prop } from "vue-property-decorator";
import { profileModule } from "@store/namespaces";
import { ProfileGetters } from "@store/modules/profile/types";

import TransValue from "./components/TransValue/TransValue";

@Component({
  inheritAttrs: false,
  components: {
    TransValue,
  },
})
export default class Trans extends Vue {
  @profileModule.Getter("profileLanguage")
  private readonly profileLanguageGetter!: ProfileGetters["profileLanguage"];

  @Prop({ type: String }) private readonly tag?: string;
  @Prop({ type: String, required: true }) private readonly transKey!: string;

  private get profileLanguageCode() {
    return this.profileLanguageGetter(this.$vuetify.lang.current);
  }
}
